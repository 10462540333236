import revive_payload_client_Frd8gASYWa from "/opt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3Bi0E2Ktsf from "/opt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CrWB4n4PyO from "/opt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_T5aD6w6QZh from "/opt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_yxgjJ9XxnV from "/opt/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_aQoh9E5uaZ from "/opt/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/app/.nuxt/components.plugin.mjs";
import prefetch_client_5ZRYOjuei0 from "/opt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_kCUITUWXUN from "/opt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_cKrA5WGj38 from "/opt/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import analytics_client_mtqVw3YRXk from "/opt/app/plugins/analytics.client.ts";
import country_client_EqPXODu55P from "/opt/app/plugins/country.client.ts";
import errorHandler_x1Y4n5NP4w from "/opt/app/plugins/errorHandler.ts";
import firebase_client_zXNDa0wxFH from "/opt/app/plugins/firebase.client.ts";
import naive_ui_cjnU5TlW5h from "/opt/app/plugins/naive-ui.ts";
import sentry_client_shVUlIjFLk from "/opt/app/plugins/sentry.client.ts";
export default [
  revive_payload_client_Frd8gASYWa,
  unhead_3Bi0E2Ktsf,
  router_CrWB4n4PyO,
  payload_client_T5aD6w6QZh,
  check_outdated_build_client_yxgjJ9XxnV,
  plugin_vue3_aQoh9E5uaZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5ZRYOjuei0,
  chunk_reload_client_kCUITUWXUN,
  plugin_cKrA5WGj38,
  analytics_client_mtqVw3YRXk,
  country_client_EqPXODu55P,
  errorHandler_x1Y4n5NP4w,
  firebase_client_zXNDa0wxFH,
  naive_ui_cjnU5TlW5h,
  sentry_client_shVUlIjFLk
]