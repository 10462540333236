import {useAuthStore} from "~/store/auth";
import * as ct from "countries-and-timezones";


export const useUserStore = defineStore('user', {
    state: () => ({
        userProfile: {},
        auth: useAuthStore(),
        pending: ref(true),
        country: 'US',
    }),
    actions: {
        detectCountry: async function () {
            const country = useRequestHeaders(['X-Region'])
            if (country['x-region']) return country['x-region'].toUpperCase()
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const region = ct.getCountryForTimezone(timezone)
            if (region) {
                this.country = region.id
            } else {
                this.country = 'US'
            }



        },
        fetchUser: async function () {
            const {apiUrl} = useRuntimeConfig().public
            if (!this.auth.accessToken) {
                console.log("No access token, not fetching user data")
                return
            }

            const {pending, data, error} = await useFetch(
                '/me',
                {
                    method: "GET",
                    headers: {'X-Token': this.auth.accessToken},
                    baseURL: apiUrl,
                    transform: (result) => {
                        return result
                    }
                }
            )

            if (error.value) {
                console.log("Error ", error.value)
                if (error.value.statusCode == 401) {
                    console.log("Throwing TokenExpiredError")
                    throw new TokenExpiredError(error.value.message)
                }
            }
            console.log("Error ", error)
            this.userProfile = data
            this.pending = pending

        }
    },
    persist: {
        storage: persistedState.sessionStorage,
        pick: ['country']
    }
})